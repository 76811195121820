import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const getCompanyIdFromLocalStorage = () => {
  return localStorage.getItem("selectedCompany");
};

export const fetchViewSuperAdminVisits = createAsyncThunk(
  "ViewSuperAdminVisits/fetchViewSuperAdminVisits",
  async () => {
    const token = getTokenFromLocalStorage();
    const companyId = getCompanyIdFromLocalStorage();

    const res = await fetch(`${apiUrl}/super-admin/visits/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    const filteredData = final.data.filter(
      (visits) => visits?.visits?.is_active === 1
    );

    return { ...final, data: filteredData };
  }
);

const ViewSuperAdminVisitsSlice = createSlice({
  name: "ViewSuperAdminVisits",
  initialState: {
    ViewSuperAdminVisitsData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewSuperAdminVisits.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewSuperAdminVisits.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewSuperAdminVisitsData = action.payload;
    });
    builder.addCase(fetchViewSuperAdminVisits.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewSuperAdminVisitsSlice.reducer;
