import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import VisibilityIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import Checkbox from "@mui/material/Checkbox";
import CheckboxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckboxIcon from "@mui/icons-material/CheckBox";
import { DialogContentText } from "@mui/material";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import L from "leaflet";
import redPinIcon from "../../../Assets/images/pin.png";
import Topbar from "../../global/Topbar";
import { UpdateDealer } from "../../../Redux/Slice/Dealer/UpdateDealerSlice";
import Autocomplete from "@mui/material/Autocomplete";
import ExcelDownload from "../../Excel/ExcelDownload";
import { fetchViewAdminDealer } from "../../../Redux/Slice/Admin/Dealer/ViewAdminDealerSlice";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AdminDealer() {
  const dispatch = useDispatch();
  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30], // Adjust the size as needed
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });
  const DealerData = useSelector(
    (state) => state.ViewAdminDealer.ViewAdminDealerData?.data
  );
  const EmpData = useSelector((state) => state.ViewUser.ViewUserData?.data);
  const loading = useSelector((state) => state.ViewAdminDealer.isLoader);
  const error = useSelector((state) => state.ViewAdminDealer.isError);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const [downloadExcel, setdownloadExcel] = useState(false);
  const [excelData, setexcelData] = useState();
  const [selectedFilterOptions, setSelectedFilterOptions] = React.useState({
    company: [],
    department: [],
    designation: [],
    id: [],
    emp_code: [],
  });
  React.useEffect(() => {
    dispatch(fetchViewAdminDealer());
    dispatch(fetchViewUser());
  }, [dispatch]);
  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [formErrors, setFormErrors] = useState({
    name: "",
    address: "",
    // geotag_address: "",
    concered_name: "",
    number: "",
    email: "",
    designation: "",
    code: "",
  });

  //   update
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editData, setEditData] = React.useState({
    id: "",
    address: "",
    concered_person: "",
    number: "",
    enail: "",
    designation: "",
    longitude: "",
    latitude: "",
    empCode: "",
  });

  const handleEditModalChange = (field, value) => {
    setEditData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleEdit = () => {
    const {
      id,
      address,
      concered_person,
      number,
      email,
      designation,
      longitude,
      latitude,
      empCode,
    } = editData;

    const credentials = {
      id,
      address,
      concered_person,
      number,
      email,
      designation,
      longitude,
      latitude,
      empCode,
    };

    dispatch(UpdateDealer(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchViewAdminDealer());
        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Dealer:", error);
        toast.error("Error updating Dealer. Please try again.");
      });
  };

  const handleOpenEditModal = (dealerData) => {
    if (dealerData) {
      setSelectedData(dealerData);
      setEditData({
        id: dealerData.id,
        dealerName: dealerData.dealerName,
        email: dealerData.email,
        address: dealerData.address,
        concered_person: dealerData.concered_person,
        dealer_code: dealerData.dealer_code,
        number: dealerData.number,
        designation: dealerData.designation,
        longitude: dealerData.longitude,
        latitude: dealerData.latitude,
        empCode: dealerData.emp_code,
      });
    } else {
      setEditData({
        id: "",
        address: "",
        concered_person: "",
        number: "",
        email: "",
        designation: "",
        longitude: "",
        latitude: "",
      });
    }
    setOpenEditModal(true);
  };
  //   search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };
  const [selectedEmpCodes, setSelectedEmpCodes] = React.useState([]);
  const filteredData = DealerData?.filter(
    (client) =>
      (selectedEmpCodes.length === 0 ||
        selectedEmpCodes.includes(client?.emp_code)) &&
      (client.dealerName.toLowerCase().includes(searchTerm) ||
        client.concered_person.toLowerCase().includes(searchTerm))
  );
  const sortedData = filteredData?.sort((a, b) =>
    a.dealerName.localeCompare(b.dealerName)
  );
  const handleEmpCodeChange = (event, newValue) => {
    setSelectedEmpCodes(newValue);
  };
  // Slice the data for pagination
  const slicedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [mapLocation, setMapLocation] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal = (dealerData) => {
    const latitude = parseFloat(dealerData.latitude);
    const longitude = parseFloat(dealerData.longitude);

    // Check if latitude and longitude are available
    if (isNaN(latitude) || isNaN(longitude)) {
      // Handle the case where latitude and/or longitude are not available
      toast.error("Latitude and/or Longitude not available for this Dealer.");
      return;
    }

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };

  // all map
  const [openMapModalAll, setOpenMapModalAll] = React.useState(false);
  const [mapLocations, setMapLocations] = React.useState([]);

  const handleOpenMapModalAll = () => {
    setMapLocations(
      DealerData.map((dealer) => ({
        latitude: parseFloat(dealer.latitude),
        longitude: parseFloat(dealer.longitude),
      })).filter(
        (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
      )
    );
    setOpenMapModalAll(true);
  };

  const handleCloseMapModalAll = () => {
    setOpenMapModalAll(false);
  };
  // download excel
  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    setexcelData(table);
  };

  const disabledownload = () => {
    setdownloadExcel(false);
  };

  const validMapLocations = mapLocations.filter(
    (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
  );

  let mapBounds = null;
  if (validMapLocations.length > 0) {
    const latLngs = validMapLocations.map((location) =>
      L.latLng(location.latitude, location.longitude)
    );
    mapBounds = L.latLngBounds(latLngs);
  }

  return (
    <>
      <Topbar />
      <div style={{ padding: "20px" }}>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid item xs={6} md={6} lg={5} style={{ marginBottom: "10px" }}>
            <FormControl>
              <h4 style={{ color: "#ffffff" }}>Dealer Details</h4>
            </FormControl>
          </Grid>
          <Grid item xs={6} md={6} lg={1}>
            <IconButton
              onClick={() => handleOpenMapModalAll()}
              sx={{ color: "#fff", marginBottom: "10px" }}
            >
              <MapIcon />
            </IconButton>
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={Array.from(
                new Set(
                  DealerData
                    ? DealerData.filter((client) => client && client.emp_code) // Filter out clients without user data or empCode
                        .map((client) => client.emp_code)
                    : []
                )
              )} // Provide an empty array if clientData is undefined
              disableCloseOnSelect
              onChange={handleEmpCodeChange}
              value={selectedEmpCodes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              style={{ height: "33px", marginBottom: "10px" }}
              renderInput={(params) => (
                <Paper
                  component="form"
                  style={{ padding: "6px", width: "120px" }}
                  ref={params.InputProps.ref}
                >
                  <input
                    {...params.inputProps}
                    placeholder="Select User"
                    style={{ width: "110px" }}
                  />
                </Paper>
              )}
            />
          </Grid>
          <Grid item xs={6} md={3} lg={2}>
            <InputBase
              sx={{ marginBottom: "10px" }}
              placeholder="Search by Firm or Concerned Person"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                outline: "none",
                transition: "background-color 0.3s, box-shadow 0.3s",
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2}>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#fff",

                marginBottom: "10px",
              }}
              onClick={donloadexcel}
            >
              Download
            </Button>
          </Grid>
        </Grid>

        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : DealerData && DealerData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Code
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Dealer Code
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Concerned Person
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Address
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Territory
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Designation
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Geotagged
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Action
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Map
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((dealerData, index) => (
                    <StyledTableRow key={dealerData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>{" "}
                      <StyledTableCell>{dealerData.id}</StyledTableCell>{" "}
                      <StyledTableCell>{dealerData.user?.name}</StyledTableCell>
                      <StyledTableCell>{dealerData.dealerName}</StyledTableCell>
                      <StyledTableCell>
                        {dealerData.dealer_code}
                      </StyledTableCell>
                      <StyledTableCell>
                        {dealerData.concered_person}
                      </StyledTableCell>
                      <StyledTableCell>{dealerData.address}</StyledTableCell>{" "}
                      <StyledTableCell>{dealerData.territory}</StyledTableCell>
                      <StyledTableCell>{dealerData.email}</StyledTableCell>
                      <StyledTableCell>{dealerData.number}</StyledTableCell>
                      <StyledTableCell>
                        {dealerData.designation}
                      </StyledTableCell>
                      <StyledTableCell>
                        {dealerData.latitude && dealerData.longitude
                          ? "Yes"
                          : "No"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenEditModal(dealerData)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dealerData.latitude && dealerData.longitude ? (
                          <IconButton
                            color="secondary"
                            onClick={() => handleOpenMapModal(dealerData)}
                          >
                            <MapIcon />
                          </IconButton>
                        ) : null}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredData.length]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No User available</div>
        )}
        <div style={{ display: downloadExcel ? "block" : "none" }}>
          {downloadExcel ? (
            <ExcelDownload
              data={excelData}
              name="dealer-data"
              onsubmit={disabledownload}
            />
          ) : (
            <></>
          )}
        </div>
        <Dialog open={openEditModal} maxWidth="md" onClose={handleCloseModal}>
          <DialogTitle
            sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
          >
            Update Dealer
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <input
                    type="hidden"
                    id="eid"
                    value={selectedData ? selectedData.id : ""}
                  />
                  <Grid item xs={4}>
                    <h5>Firm Name</h5>
                    <TextField
                      id="dealerName"
                      label="dealerName"
                      value={selectedData ? selectedData.dealerName : ""}
                      fullWidth
                      margin="normal"
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Concerned Person</h5>
                    <TextField
                      label="Concerned Person"
                      fullWidth
                      margin="normal"
                      name="concered_person"
                      id="concered_person"
                      defaultValue={
                        selectedData ? selectedData.concered_person : ""
                      }
                      onChange={(e) =>
                        handleEditModalChange("concered_person", e.target.value)
                      }
                      error={!!formErrors.number}
                      helperText={formErrors.number}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Dealer Code</h5>
                    <TextField
                      label="Dealer Code"
                      fullWidth
                      margin="normal"
                      name="dealer_code"
                      id="dealer_code"
                      value={selectedData ? selectedData.dealer_code : ""}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Phone</h5>
                    <TextField
                      label="Phone"
                      fullWidth
                      margin="normal"
                      name="number"
                      id="number"
                      defaultValue={selectedData ? selectedData.number : ""}
                      onChange={(e) =>
                        handleEditModalChange("number", e.target.value)
                      }
                      error={!!formErrors.number}
                      helperText={formErrors.number}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Email</h5>
                    <TextField
                      label="Email"
                      fullWidth
                      margin="normal"
                      name="email"
                      id="email"
                      defaultValue={selectedData ? selectedData.email : ""}
                      onChange={(e) =>
                        handleEditModalChange("email", e.target.value)
                      }
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Designation</h5>
                    <TextField
                      label="Designation"
                      fullWidth
                      margin="normal"
                      name="designation"
                      id="designation"
                      defaultValue={
                        selectedData ? selectedData.designation : ""
                      }
                      onChange={(e) =>
                        handleEditModalChange("designation", e.target.value)
                      }
                      error={!!formErrors.designation}
                      helperText={formErrors.designation}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>{" "}
                  <Grid item xs={4}>
                    <h5>Assign To*</h5>
                    <FormControl fullWidth margin="normal">
                      <InputLabel htmlFor="role_id">Select Name</InputLabel>
                      <Select
                        label="empCode"
                        name="empCode"
                        id="empCode"
                        value={editData.empCode}
                        onChange={(e) => {
                          setEditData({
                            ...editData,
                            empCode: e.target.value,
                          });
                        }}
                        sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                      >
                        {EmpData && EmpData ? (
                          EmpData.map((emp) => (
                            <MenuItem key={emp.id} value={emp.empCode}>
                              {emp.name} | Emp Code: {emp.empCode}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem value="">Loading...</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <h5>Address</h5>
                    <TextField
                      label="Address"
                      fullWidth
                      multiline
                      rows={4} // Adjust the number of rows as needed
                      margin="normal"
                      name="address"
                      id="address"
                      defaultValue={selectedData ? selectedData.address : ""}
                      onChange={(e) =>
                        handleEditModalChange("address", e.target.value)
                      }
                      error={!!formErrors.address}
                      helperText={formErrors.address}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>
          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseEditModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleEdit}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openMapModal} onClose={handleCloseMapModal}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              center={[mapLocation.latitude, mapLocation.longitude]}
              zoom={13}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[mapLocation.latitude, mapLocation.longitude]}
                icon={locationOnIcon}
              >
                {/* <Popup>
                Location: {mapLocation.latitude}, {mapLocation.longitude}
              </Popup> */}
              </Marker>
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModal} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openMapModalAll} onClose={handleCloseMapModalAll}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              bounds={mapBounds}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {DealerData?.map((dealer) => {
                const latitude = parseFloat(dealer.latitude);
                const longitude = parseFloat(dealer.longitude);
                if (isNaN(latitude) || isNaN(longitude)) {
                  // Skip markers with invalid latitude or longitude
                  return null;
                }
                return (
                  <Marker
                    key={dealer.id}
                    position={[latitude, longitude]}
                    icon={locationOnIcon}
                  >
                    <Popup>{dealer.dealerName}</Popup>
                  </Marker>
                );
              })}
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModalAll} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
