import React from "react";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import { Link } from "react-router-dom";
import { fetchViewDashboard } from "../../../Redux/Slice/Dashboard/ViewDashboardSlice";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
import { fetchViewAdminClient } from "../../../Redux/Slice/Admin/Clients/ViewAdminClientsSlice";
import { fetchViewAdminSubdealer } from "../../../Redux/Slice/Admin/Subdealer/ViewAdminSubdealerSlice";
import Topbar from "../../global/Topbar";
import { fetchViewAdminDealer } from "../../../Redux/Slice/Admin/Dealer/ViewAdminDealerSlice";
import { fetchViewAdminVisits } from "../../../Redux/Slice/Admin/Visits/ViewAdminVisitsSlice";
import { fetchViewAdminPjp } from "../../../Redux/Slice/Admin/Pjp/ViewAdminPjpSlice";

const Dashboard = () => {
  const dispatch = useDispatch();

  const DashboardData = useSelector(
    (state) => state.ViewDashboard.ViewDashboardData
  );

  const AllPjp = useSelector(
    (state) => state.ViewAdminPjp.ViewAdminPjpData?.data
  );
  const countAllPjp = () => {
    if (!AllPjp || !Array.isArray(AllPjp)) {
      return 0;
    }
    return AllPjp.length;
  };
  //
  const DealerData = useSelector(
    (state) => state.ViewAdminDealer.ViewAdminDealerData?.data
  );
  const countDealer = () => {
    if (!DealerData || !Array.isArray(DealerData)) {
      return 0;
    }
    return DealerData.length;
  };
  //
  const SubdealerData = useSelector(
    (state) => state.ViewAdminSubdealer.ViewAdminSubdealerData?.data
  );
  const countSubdealer = () => {
    if (!SubdealerData || !Array.isArray(SubdealerData)) {
      return 0;
    }
    return SubdealerData.length;
  };
  const ClientData = useSelector(
    (state) => state.ViewAdminClients.ViewAdminClientData?.data
  );
  const countDealersByRole = (categoeryId) => {
    if (!ClientData || !ClientData || !Array.isArray(ClientData)) {
      return 0;
    }
    return ClientData.filter((Client) => Client.categoery_id === categoeryId)
      .length;
  };
  const EmpData = useSelector((state) => state.ViewUser.ViewUserData?.data);

  const countUser = () => {
    if (!EmpData || !Array.isArray(EmpData)) {
      return 0;
    }

    // Filter out active users where is_active equals 1
    const activeUsers = EmpData.filter((user) => user.is_active === 1);

    return activeUsers.length;
  };

  //
  React.useEffect(() => {
    dispatch(fetchViewAdminDealer());
    dispatch(fetchViewAdminPjp());
    dispatch(fetchViewDashboard());
    dispatch(fetchViewUser());
    dispatch(fetchViewAdminVisits());
    dispatch(fetchViewAdminClient());
    dispatch(fetchViewAdminSubdealer());
  }, [dispatch]);
  const prepareGraphData = () => {
    if (!DashboardData || typeof DashboardData !== "object") {
      return [];
    }

    const graphData = Object.keys(DashboardData).map((month) => ({
      month,
      value: DashboardData[month],
    }));

    return graphData;
  };
  const calculateTotalVisits = () => {
    if (!DashboardData || typeof DashboardData !== "object") {
      return 0;
    }

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString("default", {
      month: "long",
    });

    return DashboardData[currentMonth] || 0;
  };
  const Visits = useSelector(
    (state) => state.ViewAdminVisits.ViewAdminVisitsData?.data
  );

  console.log("Visits", Visits);

  const countTotalVisits = () => {
    let totalVisits = 0;
    if (Visits) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      Visits.forEach((visit) => {
        const createdAtDate = new Date(visit?.visits?.created_at);
        const visitMonth = createdAtDate.getMonth() + 1;
        const visitYear = createdAtDate.getFullYear();
        if (visitMonth === currentMonth && visitYear === currentYear) {
          totalVisits += 1; // Increment the count for each visit in the current month
        }
      });
    }
    return totalVisits;
  };

  // Call the function to get the total number of visits
  const totalVisits = countTotalVisits();

  const countPositiveCalls = () => {
    let positiveCalls = 0;
    if (Visits) {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      positiveCalls = Visits.filter(
        (visit) =>
          (visit?.visits?.order_recipt === "Yes" ||
            visit?.visits?.payment_status === "Yes") &&
          new Date(visit?.visits?.created_at).getMonth() + 1 === currentMonth &&
          new Date(visit?.visits?.created_at).getFullYear() === currentYear
      ).length;
    }
    return positiveCalls;
  };

  const Productivity = () => {
    return (countPositiveCalls() / totalVisits) * 100;
  };
  const countVisitsInCurrentMonth = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const visitsInCurrentMonth = Visits?.filter((visit) => {
      const createdAtDate = new Date(visit?.visits?.created_at);
      const visitMonth = createdAtDate.getMonth() + 1;
      const visitYear = createdAtDate.getFullYear();

      return visitMonth === currentMonth && visitYear === currentYear;
    });

    return visitsInCurrentMonth?.length;
  };

  const graphData = prepareGraphData();

  const prepareDonutData = (visits) => {
    if (!visits || !Array.isArray(visits)) {
      return [];
    }

    const visitsByEmpCode = visits.reduce((acc, visit) => {
      const empCode = visit?.visits?.emp_code;
      const empName = visit?.visits?.user_name?.name;
      if (empCode && empName) {
        if (!acc[empCode]) {
          acc[empCode] = { name: empName, empCode, value: 0 };
        }
        acc[empCode].value += 1;
      }
      return acc;
    }, {});

    return Object.values(visitsByEmpCode);
  };

  const donutData = prepareDonutData(Visits);
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <div style={{ background: "linear-gradient(to right, #ffedff, #fff)" }}>
      <Topbar />
      <Grid container spacing={3} style={{ padding: "30px" }}>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f8ee1f", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Pjp
                </Typography>
                <Typography variant="h2" component="div">
                  {countAllPjp()}
                </Typography>
              </div>{" "}
              <Link to="/admin/pjp">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#e739fc", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Dealer
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealer()}
                </Typography>
              </div>{" "}
              <Link to="/admin/dealer">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#95fba0", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Subdealer
                </Typography>
                <Typography variant="h2" component="div">
                  {countSubdealer()}
                </Typography>
              </div>
              <Link to="/admin/subdealer">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f9bcff", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Architect
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole(5)}
                </Typography>
              </div>{" "}
              <Link to="/admin/architect">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#9fa3f2", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Builder
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole(6)}
                </Typography>
              </div>{" "}
              <Link to="/admin/builder">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#bf62ff", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Contractor
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole(7)}
                </Typography>
              </div>{" "}
              <Link to="/admin/contractor">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f9ff59", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Competitor
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole(8)}
                </Typography>
              </div>{" "}
              <Link to="/admin/competitor">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#fe8553", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Other
                </Typography>
                <Typography variant="h2" component="div">
                  {countDealersByRole(10)}
                </Typography>
              </div>{" "}
              <Link to="/admin/others">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#42eddf", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Total Users
                </Typography>
                <Typography variant="h2" component="div">
                  {countUser()}
                </Typography>
              </div>{" "}
              <Link to="/users">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#f0ff95", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Total Calls
                </Typography>
                <Typography variant="h2" component="div">
                  {countVisitsInCurrentMonth()}
                </Typography>
              </div>{" "}
              <Link to="/admin/monthly-visits">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#5cfd35", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Positive Calls
                </Typography>
                <Typography variant="h2" component="div">
                  {countPositiveCalls()}
                </Typography>
              </div>{" "}
              <Link to="/admin/monthly-visits">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card style={{ backgroundColor: "#e15ff4", height: "95px" }}>
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5" component="div">
                  Productivity
                </Typography>
                <Typography variant="h2" component="div">
                  {Productivity().toFixed(2)}%
                </Typography>
              </div>{" "}
              <Link to="/admin/monthly-visits">
                <Button size="small" style={{ color: "#575757" }}>
                  Learn More
                </Button>
              </Link>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            style={{
              backgroundColor: "#90ff86",
              color: "#fff",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "30px",
                }}
              >
                <Typography
                  variant="h2"
                  component="div"
                  style={{ color: "#000" }}
                >
                  Total Visits
                </Typography>
                <Typography
                  variant="h1"
                  component="div"
                  style={{ color: "#000" }}
                >
                  {totalVisits}
                </Typography>
              </div>
              <ResponsiveContainer height={400}>
                <BarChart data={graphData}>
                  <XAxis dataKey="month" tick={{ fill: "#000" }} />
                  <YAxis tick={{ fill: "#000" }} />
                  <Tooltip
                    contentStyle={{ backgroundColor: "#fff", color: "#d169dd" }}
                  />
                  <Bar dataKey="value" fill="#d169dd" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
            <CardActions>
              <Link to="">
                <Button size="small" style={{ color: "#000" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card
            style={{
              backgroundColor: "#90ff86",
              color: "#fff",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CardContent style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h2"
                component="div"
                style={{ color: "#000" }}
              >
                Visits by Employee
              </Typography>
              <ResponsiveContainer height={440}>
                <PieChart>
                  <Pie
                    data={donutData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={150}
                    innerRadius={80}
                    fill="#8884d8"
                    label={({ name, empCode }) => `${name} (${empCode})`}
                  >
                    {donutData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={getRandomColor()} />
                    ))}
                  </Pie>
                  <Legend />
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
            <CardActions>
              <Link to="">
                <Button size="small" style={{ color: "#000" }}>
                  Learn More
                </Button>
              </Link>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
