import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import VisibilityIcon from "@mui/icons-material/Edit";
import MapIcon from "@mui/icons-material/Map";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import ExcelDownload from "../../Excel/ExcelDownload";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
} from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import L from "leaflet";
import redPinIcon from "../../../Assets/images/pin.png";
import Topbar from "../../global/Topbar";
import { UpdateSubdealer } from "../../../Redux/Slice/Subdealer/UpdateSubdealerSlice";
import { fetchViewAdminSubdealer } from "../../../Redux/Slice/Admin/Subdealer/ViewAdminSubdealerSlice";
import { addsubdealer } from "../../../Redux/Slice/SuperAdmin/SuperadminWork";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import * as XLSX from "xlsx";
import { fetchViewSuperAdminSubdealer } from "../../../Redux/Slice/SuperAdmin/ViewSuperSubdealerSlice";
import TopbarSuperAdmin from "../../global/TopBarSuperAdmin";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SuperSubDealer() {
  const dispatch = useDispatch();
  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30], // Adjust the size as needed
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });
  const DealerData = useSelector(
    (state) => state.ViewSuperSubdealer.ViewSuperAdminSubdealerData?.data
  );
  // console.log("DealerData", DealerData);
  const loading = useSelector((state) => state.ViewSuperSubdealer.isLoader);
  const error = useSelector((state) => state.ViewSuperSubdealer.isError);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = React.useState(false);
  const [downloadExcel, setdownloadExcel] = useState(false);
  const [excelData, setexcelData] = useState();

  // view list
  React.useEffect(() => {
    dispatch(fetchViewSuperAdminSubdealer());
  }, [dispatch]);
  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [formErrors, setFormErrors] = useState({
    name: "",
    address: "",
    // geotag_address: "",
    concered_name: "",
    number: "",
    email: "",
    designation: "",
    code: "",
  });

  //   update
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [editData, setEditData] = React.useState({
    id: "",
    address: "",
    concered_person: "",
    number: "",
    designation: "",
    longitude: "",
    latitude: "",
  });

  const handleEditModalChange = (field, value) => {
    setEditData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  const handleEdit = () => {
    const {
      id,
      address,
      concered_person,
      number,
      designation,
      longitude,
      latitude,
    } = editData;

    const credentials = {
      id,
      address,
      concered_person,
      number,
      designation,
      longitude,
      latitude,
    };

    dispatch(UpdateSubdealer(credentials))
      .then(() => {
        // Reload data after successful update
        dispatch(fetchViewSuperAdminSubdealer());
        // Close the edit modal
        handleCloseEditModal();
      })
      .catch((error) => {
        console.error("Error updating Dealer:", error);
        toast.error("Error updating Dealer. Please try again.");
      });
  };

  const handleOpenEditModal = (dealerData) => {
    if (dealerData) {
      setSelectedData(dealerData);
      setEditData({
        id: dealerData.id,
        name: dealerData.name,
        email: dealerData.email,
        address: dealerData.address,
        concered_person: dealerData.concered_person,
        sub_dealer_code: dealerData.sub_dealer_code,
        number: dealerData.number,
        designation: dealerData.designation,
        longitude: dealerData.longitude,
        latitude: dealerData.latitude,
      });
    } else {
      setEditData({
        id: "",
        address: "",
        concered_person: "",
        number: "",
        designation: "",
        longitude: "",
        latitude: "",
      });
    }
    setOpenEditModal(true);
  };
  //   search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    // Update the page to the first one when the search term changes
    setPage(0);
  };

  const filteredData = DealerData?.filter(
    (dealerData) =>
      dealerData.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      dealerData.concered_person
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
  );

  // Sort the filtered data alphabetically by dealerName
  const sortedData = filteredData?.sort((a, b) => a.name.localeCompare(b.name));

  // Slice the data for pagination
  const slicedData = sortedData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [openMapModal, setOpenMapModal] = React.useState(false);
  const [mapLocation, setMapLocation] = React.useState({
    latitude: 0,
    longitude: 0,
  });

  const handleOpenMapModal = (dealerData) => {
    const latitude = parseFloat(dealerData.latitude);
    const longitude = parseFloat(dealerData.longitude);

    // Check if latitude and longitude are available
    if (isNaN(latitude) || isNaN(longitude)) {
      // Handle the case where latitude and/or longitude are not available
      toast.error("Latitude and/or Longitude not available for this Dealer.");
      return;
    }

    setMapLocation({
      latitude: latitude,
      longitude: longitude,
    });
    setOpenMapModal(true);
  };

  const handleCloseMapModal = () => {
    setOpenMapModal(false);
  };

  // all map
  const [openMapModalAll, setOpenMapModalAll] = React.useState(false);
  const [mapLocations, setMapLocations] = React.useState([]);

  const handleOpenMapModalAll = () => {
    setMapLocations(
      DealerData.map((dealer) => ({
        latitude: parseFloat(dealer.latitude),
        longitude: parseFloat(dealer.longitude),
      })).filter(
        (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
      )
    );
    setOpenMapModalAll(true);
  };

  const handleCloseMapModalAll = () => {
    setOpenMapModalAll(false);
  };
  const donloadexcel = (e) => {
    try {
      e.preventDefault();
      const url =
        "https://balaji-api.appris.co.in/public/storage/dealer/dealer.csv";
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.download = "dealer-format.csv";
      anchor.style.display = "none"; // Hide the anchor element
      document.body.appendChild(anchor); // Append anchor to the DOM
      anchor.click();
      document.body.removeChild(anchor); // Clean up: remove anchor from the DOM after download
    } catch (error) {
      console.log(error);
      // handle error
    }
  };
  const handleChange = (event) => {
    const file = event.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        const allSheetData = {};

        workbook.SheetNames.forEach((sheetName) => {
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          allSheetData[sheetName] = jsonData.slice(1);
        });

        const sectionsArray = Object.entries(allSheetData);
        dispatch(addsubdealer(sectionsArray));
      } catch (error) {
        console.error("Error reading or parsing Excel file:", error);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const validMapLocations = mapLocations.filter(
    (location) => !isNaN(location.latitude) && !isNaN(location.longitude)
  );

  let mapBounds = null;
  if (validMapLocations.length > 0) {
    const latLngs = validMapLocations.map((location) =>
      L.latLng(location.latitude, location.longitude)
    );
    mapBounds = L.latLngBounds(latLngs);
  }
  return (
    <>
      <TopbarSuperAdmin />
      <div style={{ padding: "20px" }}>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid item xs={4} md={6} lg={3} style={{ marginBottom: "10px" }}>
            <FormControl fullWidth>
              <h4 style={{ color: "#ffffff" }}>Subdealer Details</h4>
            </FormControl>
          </Grid>{" "}
          <Grid item xs={8} md={6} lg={3}>
            <FormControl fullWidth margin="normal">
              <Input
                type="file"
                onChange={handleChange}
                inputProps={{
                  accept:
                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                }}
                style={{
                  backgroundColor: "#fff", // Set background color to white
                  borderRadius: "5px", // Set border radius
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Add a subtle box shadow
                  outline: "none", // Remove outline on focus
                  transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
                  border: "1px solid #ccc", // Add border to match the style
                  padding: "8px", // Adjust padding for better appearance

                  marginBottom: "10px",
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} md={3} lg={1}>
            <IconButton
              onClick={() => handleOpenMapModalAll()}
              sx={{ color: "#fff" }}
            >
              <MapIcon />
            </IconButton>
          </Grid>
          <Grid item xs={5} md={3} lg={2}>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#fff", marginRight: "10px" }}
              onClick={(e) => donloadexcel(e)}
            >
              Download
            </Button>
          </Grid>
          <Grid item xs={5} md={3} lg={3}>
            <InputBase
              placeholder="Search by Firm or Concerned Person"
              onChange={handleSearch}
              style={{
                color: "#000",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none", // Remove outline on focus
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
              }}
            />
          </Grid>
        </Grid>
        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : DealerData && DealerData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Firm Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Subdealer Code
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Concerned Person
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Address
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Designation
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Geotagged
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Action
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Map
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((dealerData, index) => (
                    <StyledTableRow key={dealerData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>
                      <StyledTableCell>{dealerData.name}</StyledTableCell>
                      <StyledTableCell>
                        {dealerData.sub_dealer_code}
                      </StyledTableCell>

                      <StyledTableCell>
                        {dealerData.concered_person}
                      </StyledTableCell>
                      <StyledTableCell>{dealerData.address}</StyledTableCell>
                      <StyledTableCell>{dealerData.email}</StyledTableCell>
                      <StyledTableCell>{dealerData.number}</StyledTableCell>
                      <StyledTableCell>
                        {dealerData.designation}
                      </StyledTableCell>
                      <StyledTableCell>
                        {dealerData.latitude && dealerData.longitude
                          ? "Yes"
                          : "No"}
                      </StyledTableCell>
                      <StyledTableCell>
                        <IconButton
                          color="secondary"
                          onClick={() => handleOpenEditModal(dealerData)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </StyledTableCell>
                      <StyledTableCell>
                        {dealerData.latitude && dealerData.longitude ? (
                          <IconButton
                            color="secondary"
                            onClick={() => handleOpenMapModal(dealerData)}
                          >
                            <MapIcon />
                          </IconButton>
                        ) : null}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, DealerData.length]}
              component="div"
              count={DealerData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No User available</div>
        )}
        <Dialog open={openEditModal} maxWidth="md" onClose={handleCloseModal}>
          <DialogTitle
            sx={{ background: "linear-gradient(to right, #fff, #bfbfbf)" }}
          >
            Update Dealer
          </DialogTitle>
          <div
            style={{
              background:
                "url('https://img.freepik.com/premium-vector/flat-polygonal-background_23-2148918677.jpg?w=740')",
              backgroundSize: "cover",
            }}
          >
            <div style={{ backgroundColor: "rgba(255, 255, 255, 0.7)" }}>
              <DialogContent>
                <Grid container spacing={2}>
                  <input
                    type="hidden"
                    id="eid"
                    value={selectedData ? selectedData.id : ""}
                  />
                  <Grid item xs={4}>
                    <h5>Firm Name</h5>
                    <TextField
                      id="name"
                      label="name"
                      value={selectedData ? selectedData.name : ""}
                      fullWidth
                      margin="normal"
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Concerned Person</h5>
                    <TextField
                      label="Concerned Person"
                      fullWidth
                      margin="normal"
                      name="concered_person"
                      id="concered_person"
                      defaultValue={
                        selectedData ? selectedData.concered_person : ""
                      }
                      onChange={(e) =>
                        handleEditModalChange("concered_person", e.target.value)
                      }
                      error={!!formErrors.number}
                      helperText={formErrors.number}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <h5>Dealer Code</h5>
                    <TextField
                      label="Dealer Code"
                      fullWidth
                      margin="normal"
                      name="sub_dealer_code"
                      id="sub_dealer_code"
                      value={selectedData ? selectedData.sub_dealer_code : ""}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Phone</h5>
                    <TextField
                      label="Phone"
                      fullWidth
                      margin="normal"
                      name="number"
                      id="number"
                      defaultValue={selectedData ? selectedData.number : ""}
                      onChange={(e) =>
                        handleEditModalChange("number", e.target.value)
                      }
                      error={!!formErrors.number}
                      helperText={formErrors.number}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Email</h5>
                    <TextField
                      label="Email"
                      fullWidth
                      margin="normal"
                      name="email"
                      id="email"
                      defaultValue={selectedData ? selectedData.email : ""}
                      error={!!formErrors.email}
                      helperText={formErrors.email}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <h5>Designation</h5>
                    <TextField
                      label="Designation"
                      fullWidth
                      margin="normal"
                      name="designation"
                      id="designation"
                      defaultValue={
                        selectedData ? selectedData.designation : ""
                      }
                      onChange={(e) =>
                        handleEditModalChange("designation", e.target.value)
                      }
                      error={!!formErrors.designation}
                      helperText={formErrors.designation}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h5>Address</h5>
                    <TextField
                      label="Address"
                      fullWidth
                      multiline
                      rows={4} // Adjust the number of rows as needed
                      margin="normal"
                      name="address"
                      id="address"
                      defaultValue={selectedData ? selectedData.address : ""}
                      onChange={(e) =>
                        handleEditModalChange("address", e.target.value)
                      }
                      error={!!formErrors.address}
                      helperText={formErrors.address}
                      sx={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
            </div>
          </div>
          <DialogActions
            sx={{ background: "linear-gradient(to right, #bfbfbf, #fff)" }}
          >
            <Button
              onClick={handleCloseEditModal}
              color="secondary"
              style={{
                background: "#fff",
                height: "100%",
              }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleEdit}
              style={{
                background: "linear-gradient(to right, #2d0031, #4d0054)",
                height: "100%",
                color: "white",
              }}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openMapModal} onClose={handleCloseMapModal}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              center={[mapLocation.latitude, mapLocation.longitude]}
              zoom={13}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[mapLocation.latitude, mapLocation.longitude]}
                icon={locationOnIcon}
              >
                {/* <Popup>
                Location: {mapLocation.latitude}, {mapLocation.longitude}
              </Popup> */}
              </Marker>
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModal} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={openMapModalAll} onClose={handleCloseMapModalAll}>
          <DialogTitle>Location on Map</DialogTitle>
          <DialogContent>
            <MapContainer
              bounds={mapBounds}
              style={{ height: "400px", width: "400px" }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {DealerData?.map((dealer) => {
                const latitude = parseFloat(dealer.latitude);
                const longitude = parseFloat(dealer.longitude);
                if (isNaN(latitude) || isNaN(longitude)) {
                  // Skip markers with invalid latitude or longitude
                  return null;
                }
                return (
                  <Marker
                    key={dealer.id}
                    position={[latitude, longitude]}
                    icon={locationOnIcon}
                  >
                    <Popup>{dealer.name}</Popup>
                  </Marker>
                );
              })}
            </MapContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMapModalAll} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
  );
}
