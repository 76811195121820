import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const getCompanyIdFromLocalStorage = () => {
  return localStorage.getItem("selectedCompany");
};

export const fetchViewSuperAdminUser = createAsyncThunk(
  "ViewSuperAdminUser/fetchViewSuperAdminUser",
  async () => {
    const token = getTokenFromLocalStorage();
    const companyId = getCompanyIdFromLocalStorage();

    const res = await fetch(`${apiUrl}/super-admin/user/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewSuperAdminUserSlice = createSlice({
  name: "ViewSuperAdminUser",
  initialState: {
    ViewSuperAdminUserData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewSuperAdminUser.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewSuperAdminUser.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewSuperAdminUserData = action.payload;
    });
    builder.addCase(fetchViewSuperAdminUser.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewSuperAdminUserSlice.reducer;
