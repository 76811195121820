import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const getCompanyIdFromLocalStorage = () => {
  return localStorage.getItem("selectedCompany");
};

export const fetchViewSuperDashboard = createAsyncThunk(
  "ViewSuperDashboard/fetchViewSuperDashboard",
  async () => {
    const token = getTokenFromLocalStorage();
    const companyId = getCompanyIdFromLocalStorage();

    const res = await fetch(`${apiUrl}/super-admin/dashboard/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewSuperDashboardSlice = createSlice({
  name: "ViewSuperDashboard",
  initialState: {
    ViewSuperDashboardData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewSuperDashboard.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewSuperDashboard.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewSuperDashboardData = action.payload;
    });
    builder.addCase(fetchViewSuperDashboard.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewSuperDashboardSlice.reducer;
