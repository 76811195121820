import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const getCompanyIdFromLocalStorage = () => {
  return localStorage.getItem("selectedCompany");
};

export const fetchViewSuperAdminPjp = createAsyncThunk(
  "ViewSuperAdminPjp/fetchViewSuperAdminPjp",
  async () => {
    const token = getTokenFromLocalStorage();
    const companyId = getCompanyIdFromLocalStorage();

    const res = await fetch(`${apiUrl}/super-admin/pjp/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    const filteredData = final.data.filter((pjp) => pjp?.pjp?.is_active === 1);

    return { ...final, data: filteredData };
  }
);

const ViewSuperAdminPjpSlice = createSlice({
  name: "ViewSuperAdminPjp",
  initialState: {
    ViewSuperAdminPjpData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewSuperAdminPjp.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewSuperAdminPjp.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewSuperAdminPjpData = action.payload;
    });
    builder.addCase(fetchViewSuperAdminPjp.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewSuperAdminPjpSlice.reducer;
