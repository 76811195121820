import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { apiUrl } from "../../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const updateAdminPjp = createAsyncThunk(
  "auth/updateAdminPjp",
  async (credentials) => {
    console.log("Form Data Received:", credentials);
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/admin/pjp/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
        Accept: "*/*",
      },
      body: JSON.stringify(credentials),
    });
    const data = await res.json();
    if (data.status) {
      toast.success(data.message);
      return data;
    }
    return data;
  }
);

const AdminPjpUpdateSlice = createSlice({
  name: "updateAdminPjp",
  initialState: {
    updateAdminPjp: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.updateCase(updateAdminPjp.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.updateCase(updateAdminPjp.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.updateAdminPjp = action.payload.data;
      state.token = action.payload.token;
    });
    builder.updateCase(updateAdminPjp.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default AdminPjpUpdateSlice.reducer;
