import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const getCompanyIdFromLocalStorage = () => {
  return localStorage.getItem("selectedCompany");
};

export const fetchViewSuperAdminSubdealer = createAsyncThunk(
  "ViewSuperAdminSubdealer/fetchViewSuperAdminSubdealer",
  async () => {
    const token = getTokenFromLocalStorage();
    const companyId = getCompanyIdFromLocalStorage();

    const res = await fetch(`${apiUrl}/super-admin/sub_dealer/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    const filteredData = final.data.filter(
      (dealer) => dealer.dealear_active === "1"
    );

    return { ...final, data: filteredData };
  }
);

const ViewSuperAdminSubdealerSlice = createSlice({
  name: "ViewSuperAdminSubdealer",
  initialState: {
    ViewSuperAdminSubdealerData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewSuperAdminSubdealer.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewSuperAdminSubdealer.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewSuperAdminSubdealerData = action.payload;
    });
    builder.addCase(fetchViewSuperAdminSubdealer.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewSuperAdminSubdealerSlice.reducer;
