import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import {
  TableRow,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Topbar from "../../global/Topbar";
import redPinIcon from "../../../Assets/images/pin.png";
import "react-calendar/dist/Calendar.css";
import Autocomplete from "@mui/material/Autocomplete";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { fetchViewAdminVisits } from "../../../Redux/Slice/Admin/Visits/ViewAdminVisitsSlice";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { fetchViewSuperAdminVisits } from "../../../Redux/Slice/SuperAdmin/ViewSuperVisitsSlice";
import TopbarSuperAdmin from "../../global/TopBarSuperAdmin";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function VisitsOnMapSuperAdmin() {
  const dispatch = useDispatch();

  const locationOnIcon = new L.Icon({
    iconUrl: redPinIcon,
    iconSize: [25, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });
  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

  const [startDate, setStartDate] = useState(currentDate);
  const [endDate, setEndDate] = useState("");

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedEmpCodes, setSelectedEmpCodes] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const visits = useSelector(
    (state) => state.ViewSuperVisits.ViewSuperAdminVisitsData?.data
  );

  useEffect(() => {
    dispatch(fetchViewSuperAdminVisits(startDate, endDate, selectedCategory));
  }, [dispatch, startDate, endDate, selectedCategory]);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleEmpCodesChange = (event, newValue) => {
    setSelectedEmpCodes(newValue);
  };

  const [mapBounds, setMapBounds] = useState(null);
  const [polylines, setPolylines] = useState([]);

  useEffect(() => {
    if (visits) {
      const validLocations = visits.filter(
        (visit) =>
          !isNaN(parseFloat(visit.visits.visit_latitude)) &&
          !isNaN(parseFloat(visit.visits.visit_longitude)) &&
          (selectedCategory === "" ||
            visit.visits.category === selectedCategory) &&
          (selectedEmpCodes.length === 0 ||
            selectedEmpCodes.includes(visit.visits.emp_code))
      );

      let filteredVisits; // Define filteredVisits variable here

      if (startDate && endDate) {
        filteredVisits = validLocations.filter((visit) => {
          const visitDate = new Date(visit.visits.created_at);
          return (
            visitDate >= new Date(startDate) && visitDate <= new Date(endDate)
          );
        });

        // Remove markers and polylines from previous render
        setPolylines([]);
      } else if (startDate && !endDate) {
        filteredVisits = validLocations.filter((visit) => {
          const visitDate = new Date(visit.visits.created_at);
          return (
            visitDate.toDateString() === new Date(startDate).toDateString()
          );
        });

        // Remove markers and polylines from previous render
        setPolylines([]);
      }

      if (filteredVisits && filteredVisits.length > 0) {
        const latLngs = filteredVisits.map((visit) => [
          parseFloat(visit.visits.visit_latitude),
          parseFloat(visit.visits.visit_longitude),
        ]);
        const bounds = L.latLngBounds(latLngs);
        setMapBounds(bounds);

        const lines = [];
        for (let i = 0; i < filteredVisits.length - 1; i++) {
          const start = [
            parseFloat(filteredVisits[i].visits.visit_latitude),
            parseFloat(filteredVisits[i].visits.visit_longitude),
          ];
          const end = [
            parseFloat(filteredVisits[i + 1].visits.visit_latitude),
            parseFloat(filteredVisits[i + 1].visits.visit_longitude),
          ];
          lines.push([start, end]);
        }
        setPolylines(lines);
      }
    }
  }, [visits, startDate, endDate, selectedCategory, selectedEmpCodes]);

  const empCodeOptions = visits
    ? Array.from(new Set(visits.map((visit) => visit.visits.emp_code)))
    : [];
  // Define a function to format the date
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString(); // Use the full year
    return `${day}/${month}/${year}`;
  }

  function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  }
  function calculateTotalOrder(
    visits,
    startDate,
    endDate,
    selectedCategory,
    selectedEmpCodes
  ) {
    // Filter visits based on selected criteria
    const filteredVisits = visits.filter((visit) => {
      const visitDate = new Date(visit.visits.created_at);
      return (
        (!startDate || visitDate >= new Date(startDate)) &&
        (!endDate || visitDate <= new Date(endDate)) &&
        (!selectedCategory || visit.visits.category === selectedCategory) &&
        (!selectedEmpCodes.length ||
          selectedEmpCodes.includes(visit.visits.emp_code))
      );
    });

    // Set to keep track of unique emp_codes
    const uniqueEmpCodes = new Set();

    // Sum total_order for unique emp_codes
    let total = 0;
    filteredVisits.forEach((visit) => {
      // Check if emp_code is unique
      if (!uniqueEmpCodes.has(visit.visits.emp_code)) {
        // Add total_order to total if emp_code is unique
        total += visit.visits.total_order;
        // Add emp_code to the set
        uniqueEmpCodes.add(visit.visits.emp_code);
      }
    });

    return total;
  }
  function calculateTotalOrderYes(
    visits,
    startDate,
    endDate,
    selectedCategory,
    selectedEmpCodes
  ) {
    // Filter visits based on selected criteria
    const filteredVisits = visits.filter((visit) => {
      const visitDate = new Date(visit.visits.created_at);
      return (
        (!startDate || visitDate >= new Date(startDate)) &&
        (!endDate || visitDate <= new Date(endDate)) &&
        (!selectedCategory || visit.visits.category === selectedCategory) &&
        (!selectedEmpCodes.length ||
          selectedEmpCodes.includes(visit.visits.emp_code))
      );
    });

    // Set to keep track of unique emp_codes
    const uniqueEmpCodes = new Set();

    // Sum order_yes for unique emp_codes
    let total = 0;
    filteredVisits.forEach((visit) => {
      // Check if emp_code is unique
      if (!uniqueEmpCodes.has(visit.visits.emp_code)) {
        // Add order_yes to total if emp_code is unique
        total += visit.visits.order_yes;
        // Add emp_code to the set
        uniqueEmpCodes.add(visit.visits.emp_code);
      }
    });

    return total;
  }
  function calculateNegativeCalls(
    visits,
    startDate,
    endDate,
    selectedCategory,
    selectedEmpCodes
  ) {
    // Calculate total calls
    const totalCalls = calculateTotalOrder(
      visits,
      startDate,
      endDate,
      selectedCategory,
      selectedEmpCodes
    );

    // Calculate positive calls
    const positiveCalls = calculateTotalOrderYes(
      visits,
      startDate,
      endDate,
      selectedCategory,
      selectedEmpCodes
    );

    // Calculate difference
    const difference = totalCalls - positiveCalls;

    return difference;
  }

  const [page, setPage] = useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filteredVisits = visits?.filter((entry) => {
    const latitude = parseFloat(entry.visits.visit_latitude);
    const longitude = parseFloat(entry.visits.visit_longitude);
    const visitDate = new Date(entry.visits.created_at);
    const endDatePlusOne = endDate
      ? new Date(
          new Date(endDate).getTime() + 24 * 60 * 60 * 1000
        ).toISOString()
      : null;
    return (
      !isNaN(latitude) &&
      !isNaN(longitude) &&
      (!startDate || visitDate >= new Date(startDate)) &&
      (!endDate || visitDate <= new Date(endDatePlusOne)) &&
      (selectedCategory === "" || entry.visits.category === selectedCategory) &&
      (selectedEmpCodes.length === 0 ||
        selectedEmpCodes.includes(entry.visits.emp_code))
    );
  });
  const positiveCalls = visits
    ? visits.filter((visit) => {
        const visitDate = new Date(visit.visits.created_at);
        return (
          (!startDate || visitDate >= new Date(startDate)) &&
          (!endDate || visitDate <= new Date(endDate)) &&
          (!selectedCategory || visit.visits.category === selectedCategory) &&
          (!selectedEmpCodes.length ||
            selectedEmpCodes.includes(visit.visits.emp_code)) &&
          (visit.visits.order_recipt === "Yes" ||
            visit.visits.payment_status === "Yes")
        );
      }).length
    : 0;

  // Calculate total calls
  const totalCalls = filteredVisits?.length;

  const paginatedVisits = filteredVisits?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TopbarSuperAdmin />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <div style={{ flex: "0 0 30%", marginRight: "20px" }}>
          {" "}
          <h4
            style={{
              color: "#ffffff",
              background: "#4d0054",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
              marginTop: "20px",
            }}
          >
            Filter
          </h4>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <select
              value={selectedCategory}
              onChange={handleCategoryChange}
              style={{
                width: "180px",
                height: "30px",
                borderRadius: "5px",
                marginRight: "10px",
              }}
            >
              <option value="">All Categories</option>
              <option value="Dealer">Dealer</option>
              <option value="SubDealer">SubDealer</option>
              <option value="Architect">Architect</option>
              <option value="Builder">Builder</option>
              <option value="Contractor">Contractor</option>
              <option value="Competitor">Competitor</option>
              <option value="Other">Other</option>
            </select>
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              options={empCodeOptions}
              disableCloseOnSelect
              onChange={handleEmpCodesChange}
              value={selectedEmpCodes}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} />
                  {option}
                </li>
              )}
              style={{ height: "30px", marginRight: "3px" }}
              renderInput={(params) => (
                <Paper
                  component="form"
                  style={{ padding: "5px" }}
                  ref={params.InputProps.ref}
                >
                  <input {...params.inputProps} placeholder="Select User" />
                </Paper>
              )}
            />
          </div>
          <h4
            style={{
              color: "#ffffff",
              background: "#4d0054",
              padding: "10px",
              borderRadius: "5px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
              marginTop: "20px",
            }}
          >
            Filter By Date Range
          </h4>
          <div>
            <input
              type="date"
              value={startDate}
              onChange={handleStartDateChange}
              label="Date from"
              style={{
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "8px",
                width: "170px",
              }}
            />
            <input
              type="date"
              value={endDate}
              onChange={handleEndDateChange}
              label="Date To"
              style={{
                marginLeft: "10px",
                borderRadius: "5px",
                padding: "8px",
                width: "170px",
              }}
            />
          </div>
          <Grid container spacing={3} style={{ padding: "10px" }}>
            <Grid item xs={12}>
              <Card style={{ backgroundColor: "#f8ee1f" }}>
                <CardContent
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography variant="h5" component="div">
                      Total Calls
                    </Typography>
                    <Typography variant="h2" component="div">
                      {totalCalls}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card style={{ backgroundColor: "#2aed39de" }}>
                <CardContent
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography variant="h5" component="div">
                      Positive Calls
                    </Typography>
                    <Typography
                      variant="h2"
                      component="div"
                      style={{ position: "relative", top: "20px" }}
                    >
                      {visits
                        ? visits
                            .filter((visit) => {
                              const visitDate = new Date(
                                visit.visits.created_at
                              );
                              return (
                                (!startDate ||
                                  visitDate >= new Date(startDate)) &&
                                (!endDate || visitDate <= new Date(endDate)) &&
                                (!selectedCategory ||
                                  visit.visits.category === selectedCategory) &&
                                (!selectedEmpCodes.length ||
                                  selectedEmpCodes.includes(
                                    visit.visits.emp_code
                                  ))
                              );
                            })
                            .reduce((totalYes, visit) => {
                              if (
                                visit.visits.order_recipt === "Yes" ||
                                visit.visits.payment_status === "Yes"
                              ) {
                                return totalYes + 1;
                              }
                              return totalYes;
                            }, 0)
                        : 0}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6}>
              <Card style={{ backgroundColor: "#ff4242de" }}>
                <CardContent
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography variant="h5" component="div">
                      Negative Calls
                    </Typography>
                    <Typography
                      variant="h2"
                      component="div"
                      style={{ position: "relative", top: "20px" }}
                    >
                      {totalCalls -
                        (visits
                          ? visits
                              .filter((visit) => {
                                const visitDate = new Date(
                                  visit.visits.created_at
                                );
                                return (
                                  (!startDate ||
                                    visitDate >= new Date(startDate)) &&
                                  (!endDate ||
                                    visitDate <= new Date(endDate)) &&
                                  (!selectedCategory ||
                                    visit.visits.category ===
                                      selectedCategory) &&
                                  (!selectedEmpCodes.length ||
                                    selectedEmpCodes.includes(
                                      visit.visits.emp_code
                                    ))
                                );
                              })
                              .reduce((totalYes, visit) => {
                                if (
                                  visit.visits.order_recipt === "Yes" ||
                                  visit.visits.payment_status === "Yes"
                                ) {
                                  return totalYes + 1;
                                }
                                return totalYes;
                              }, 0)
                          : 0)}
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card style={{ backgroundColor: "#dc2bf4de" }}>
                <CardContent
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography variant="h5" component="div">
                      Productivity
                    </Typography>
                    <Typography variant="h2" component="div">
                      {totalCalls > 0
                        ? ((positiveCalls / totalCalls) * 100).toFixed(2)
                        : 0}
                      %
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        <div style={{ flex: "0 0 70%" }}>
          {mapBounds ? (
            <MapContainer
              bounds={mapBounds}
              style={{
                height: "530px",
                width: "95%",
                border: "2px solid #ccc",
                marginTop: "10px",
              }}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              {polylines.map((line, index) => (
                <Polyline key={index} positions={line} color="blue" />
              ))}
              {visits?.map((visit, index) => {
                const latitude = parseFloat(visit.visits.visit_latitude);
                const longitude = parseFloat(visit.visits.visit_longitude);
                if (
                  isNaN(latitude) ||
                  isNaN(longitude) ||
                  new Date(visit.visits.created_at) < new Date(startDate) ||
                  new Date(visit.visits.created_at) > new Date(endDate) ||
                  (selectedCategory !== "" &&
                    visit.visits.category !== selectedCategory) ||
                  (selectedEmpCodes.length > 0 &&
                    !selectedEmpCodes.includes(visit.visits.emp_code))
                ) {
                  return null;
                }
                return (
                  <Marker
                    key={visit.id}
                    position={[latitude, longitude]}
                    icon={locationOnIcon}
                  >
                    <Popup>
                      Visit No - {index + 1}
                      <br />
                      {visit.visits?.firm_name} <br />
                      Emp Code - {visit.visits?.emp_code}
                      <br />
                      Emp Name - {visit.visits.user_name?.name}
                      <br />
                      Date - {formatDate(visit.visits.created_at)}
                      <br />
                      Time - {formatTime(visit.visits.created_at)}
                    </Popup>
                  </Marker>
                );
              })}
            </MapContainer>
          ) : (
            <p>No Visits</p>
          )}
        </div>
      </div>{" "}
      <React.Fragment>
        <TableContainer component={Paper} style={{ margin: "20px" }}>
          <Table aria-label="customized table" id="exportTable">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Sl. No.
                </StyledTableCell>{" "}
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Emp Code
                </StyledTableCell>{" "}
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Emp Name
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Category
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Firm Name
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Concerned Person
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Type
                </StyledTableCell>
                <div
                  style={{
                    backgroundColor: "#7b0087",
                    width: "250px",
                    height: "73px",
                    textAlign: "center",
                    justifyContent: "center",
                    color: "#fff",
                    position: "relative",
                  }}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    Remarks
                  </span>
                </div>
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Distance
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Date
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Time
                </StyledTableCell>
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Collection
                </StyledTableCell>{" "}
                <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                  Order Received
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedVisits?.map((entry, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    {index + 1 + page * rowsPerPage}
                  </StyledTableCell>
                  <StyledTableCell>{entry.visits.emp_code}</StyledTableCell>
                  <StyledTableCell>
                    {entry.visits.user_name?.name}
                  </StyledTableCell>
                  <StyledTableCell>{entry.visits.category}</StyledTableCell>
                  <StyledTableCell>{entry.visits.firm_name}</StyledTableCell>
                  <StyledTableCell>
                    {entry.visits.concerned_person}
                  </StyledTableCell>
                  <StyledTableCell>{entry.visits.type}</StyledTableCell>
                  <StyledTableCell style={{ textAlign: "center" }}>
                    {entry.visits.remarks}
                  </StyledTableCell>
                  <StyledTableCell>
                    {parseFloat(entry.visits.distance).toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatDate(entry.visits.created_at)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatTime(entry.visits.created_at)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {entry.visits.amount ? entry.visits.amount : "No"}
                  </StyledTableCell>
                  <StyledTableCell>{entry.visits.order_recipt}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={
            filteredVisits?.length <= 10000
              ? [10, 25, 50, filteredVisits?.length]
              : [10, 25, 50, 10000]
          }
          component="div"
          count={filteredVisits?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Rows per page"
        />
      </React.Fragment>
    </>
  );
}
