// Import necessary dependencies
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { apiUrl } from "../../../apiConfig";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const UpdateAdminClient = createAsyncThunk(
  "AdminClient/UpdateAdminClient",
  async (formData) => {
    try {
      const token = getTokenFromLocalStorage();

      const response = await axios.post(
        `${apiUrl}/admin/client/update`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = response.data;
      if (data.status) {
        toast.success(data.message);
        return data;
      }
    } catch (error) {
      console.error("Error Updating AdminClient:", error);
      if (error.response && error.response.data && error.response.data.errors) {
        toast.error(error.response.data.errors);
      } else {
        toast.error("Error Updating AdminClient. Please try again.");
      }
      throw error; // Re-throw the error to be caught in the .catch() block
    }
  }
);

// Create a slice for the company state
const AdminClientUpdateSlice = createSlice({
  name: "AdminClient",
  initialState: {
    AdminClient: null,
    isAuthenticated: false,
    isLoader: false,
    isError: false,
    token: null,
  },
  extraReducers: (builder) => {
    builder.UpdateCase(UpdateAdminClient.pending, (state) => {
      state.isLoader = true;
      state.isError = false;
    });
    builder.UpdateCase(UpdateAdminClient.fulfilled, (state, action) => {
      state.isLoader = false;
      state.isAuthenticated = true;
      state.AdminClient = action.payload.data;
      state.token = action.payload.token;
    });
    builder.UpdateCase(UpdateAdminClient.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

// Export the reducer
export default AdminClientUpdateSlice.reducer;
