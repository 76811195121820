import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
const ExcelDownload = (props) => {
  let downloadSheet = 0;
  useEffect(() => {
    // Function to generate Excel file
    const exportToExcel = () => {
      if (downloadSheet == 0) {
        const wb = XLSX.utils.table_to_book(props.data); // Convert the table to a workbook
        // console.log(wb);
        XLSX.writeFile(wb, `${props.name}.xlsx`);

        props.onsubmit();
        downloadSheet += 1;
      }
      // setexcelData(table);
    };

    exportToExcel();
  }, [props]);
  return <></>;
};
export default ExcelDownload;
