import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import ExcelDownload from "../../Excel/ExcelDownload";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InputBase from "@mui/material/InputBase";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
import Topbar from "../../global/Topbar";
import TopbarSuperAdmin from "../../global/TopBarSuperAdmin";
import { fetchViewSuperAdminUser } from "../../../Redux/Slice/SuperAdmin/ViewSuperAdminUsersSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function SuperAdminUsers() {
  const dispatch = useDispatch();

  const EmpData = useSelector(
    (state) => state.ViewSuperAdminUser.ViewSuperAdminUserData?.data
  );

  const loading = useSelector((state) => state.ViewSuperAdminUser.isLoader);
  const error = useSelector((state) => state.ViewSuperAdminUser.isError);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showAllUsers, setShowAllUsers] = React.useState(false); // State to toggle displaying all users
  const [downloadExcel, setdownloadExcel] = useState(false);
  const [excelData, setexcelData] = useState();
  // view list
  React.useEffect(() => {
    dispatch(fetchViewSuperAdminUser());
  }, [dispatch]);

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // search
  const [searchTerm, setSearchTerm] = React.useState("");
  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
    setPage(0); // Update the page to the first one when the search term changes
  };

  const toggleUsersDisplay = () => {
    setShowAllUsers(!showAllUsers);
    if (!showAllUsers) {
      setSearchTerm(""); // Clear search term when switching to show all users
    }
  };
  const donloadexcel = () => {
    setdownloadExcel(true);
    const table = document.getElementById("exportTable");
    setexcelData(table);
  };

  const disabledownload = () => {
    setdownloadExcel(false);
  };

  const filteredData = EmpData?.filter(
    (empData) =>
      (showAllUsers || empData.is_active === 1) &&
      (empData.name.toLowerCase().includes(searchTerm) ||
        empData.empCode.toLowerCase().includes(searchTerm))
  );

  // Slice the data for pagination
  const slicedData = filteredData?.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <>
      <TopbarSuperAdmin />
      <div style={{ padding: "20px" }}>
        <Grid
          container
          spacing={2}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "5px",
            background: "linear-gradient(to right, #4d0054, #91009e)",
            border: "1px solid #ddd",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0)",
            borderRadius: "5px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Grid item xs={6} md={6} lg={4} style={{ marginBottom: "10px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: "#fff" }}
              // startIcon={<AddIcon />}
              onClick={donloadexcel}
            >
              Download Excel
            </Button>
          </Grid>
          <Grid item xs={6} md={3} lg={4}>
            <h4 style={{ color: "#ffffff" }}>User Details</h4>
          </Grid>
          <Grid item xs={6} md={6} lg={2}>
            <InputBase
              placeholder="Search by Name or Emp Code"
              onChange={handleSearch}
              style={{
                color: "#000",
                paddingLeft: "20px",
                padding: "3px",
                paddingRight: "5px",
                borderRadius: "5px",
                backgroundColor: "#fff", // Background color
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", // Subtle box shadow
                outline: "none", // Remove outline on focus
                transition: "background-color 0.3s, box-shadow 0.3s", // Smooth transition effect
              }}
            />
          </Grid>

          <Grid item xs={6} md={3} lg={2}>
            <button
              onClick={toggleUsersDisplay}
              style={{
                marginLeft: "10px",
                padding: "5px 10px",
                backgroundColor: showAllUsers ? "#28a745" : "#007bff",
                color: "#fff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              {showAllUsers ? "Show Active Users" : "Show All Users"}
            </button>
          </Grid>
        </Grid>

        <ToastContainer position="bottom-right" autoClose={3000} />
        {loading ? (
          <div>Loading...</div>
        ) : error ? (
          <div>Error: {error.message}</div>
        ) : EmpData && EmpData.length > 0 ? (
          <React.Fragment>
            <TableContainer component={Paper}>
              <Table aria-label="customized table" id="exportTable">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Sl. No.
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Code
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Emp Name
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Phone
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Email
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      RM
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      State
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Territory
                    </StyledTableCell>{" "}
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      App Version
                    </StyledTableCell>
                    <StyledTableCell style={{ backgroundColor: "#7b0087" }}>
                      Status
                    </StyledTableCell>{" "}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {slicedData?.map((empData, index) => (
                    <StyledTableRow key={empData.id}>
                      <StyledTableCell>
                        {index + 1 + page * rowsPerPage}
                      </StyledTableCell>{" "}
                      <StyledTableCell>{empData.empCode}</StyledTableCell>
                      <StyledTableCell>{empData.name}</StyledTableCell>{" "}
                      <StyledTableCell>{empData.phone}</StyledTableCell>{" "}
                      <StyledTableCell>{empData.email}</StyledTableCell>{" "}
                      <StyledTableCell>{empData.rm}</StyledTableCell>
                      <StyledTableCell>{empData.state}</StyledTableCell>
                      <StyledTableCell>
                        {empData.territory}
                      </StyledTableCell>{" "}
                      <StyledTableCell>
                        {empData.device?.app_version}
                      </StyledTableCell>
                      <StyledTableCell>
                        {empData.is_active === 1 ? "Active" : "Not Active"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, filteredData.length]}
              component="div"
              count={filteredData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Rows per page"
            />
          </React.Fragment>
        ) : (
          <div style={{ padding: "20px" }}>No User available</div>
        )}
      </div>
      <div style={{ display: downloadExcel ? "block" : "none" }}>
        {downloadExcel ? (
          <ExcelDownload
            data={excelData}
            name="user-data"
            onsubmit={disabledownload}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
