import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

const getCompanyIdFromLocalStorage = () => {
  return localStorage.getItem("selectedCompany");
};

export const fetchViewSuperAdminAllClient = createAsyncThunk(
  "ViewSuperAdminAllClient/fetchViewSuperAdminAllClient",
  async () => {
    const token = getTokenFromLocalStorage();
    const companyId = getCompanyIdFromLocalStorage();

    const res = await fetch(`${apiUrl}/super-admin/all-user/${companyId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewSuperAdminAllClientSlice = createSlice({
  name: "ViewSuperAdminAllClient",
  initialState: {
    ViewSuperAdminAllClientData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewSuperAdminAllClient.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewSuperAdminAllClient.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewSuperAdminAllClientData = action.payload;
    });
    builder.addCase(fetchViewSuperAdminAllClient.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewSuperAdminAllClientSlice.reducer;
