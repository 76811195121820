import React, { useEffect, useState, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Topbar from "../../global/Topbar";
import redPinIcon from "../../../Assets/images/pin-o.png";
import greenPinIcon from "../../../Assets/images/green-pin.png"; // Assuming you have a green pin icon
import { Typography } from "@mui/material";
import { apiUrl } from "../../../Redux/apiConfig";
import LockIcon from "@mui/icons-material/Lock";
import { useDispatch, useSelector } from "react-redux";
import { fetchViewUser } from "../../../Redux/Slice/Users/ViewUserSlice";
import { addTracker } from "../../../Redux/Slice/Users/AddTrackUserSlice";

export default function TrackEmp() {
  const dispatch = useDispatch();
  const [locations, setLocations] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState(() => {
    // Retrieve selectedEmployees from localStorage, if available
    const storedEmployees = localStorage.getItem("selectedEmployees");
    return storedEmployees ? JSON.parse(storedEmployees) : [];
  });
  const [employeeNames, setEmployeeNames] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [alertDisplayed, setAlertDisplayed] = useState(false); // State to track if alert has been displayed
  const mapRef = useRef(null);

  const EmpData = useSelector((state) => state.ViewUser.ViewUserData?.data);
  console.log("EmpData", EmpData);
  React.useEffect(() => {
    dispatch(fetchViewUser());
  }, [dispatch]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("Token not found in local storage");
          return;
        }

        const response = await fetch(`${apiUrl}/sales/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.log("Failed to fetch user profile:", response.statusText);
          return;
        }

        const data = await response.json();
        setUserProfile(data.data);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          console.log("Token not found in local storage");
          return;
        }

        const response = await fetch(`${apiUrl}/admin/geo-tage`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          console.log("Failed to fetch data:", response.statusText);
          return;
        }

        const data = await response.json();
        const allEmployeeNames = data.data.map((location) => location.empCode);
        setEmployeeNames([...new Set(allEmployeeNames)]); // Extract unique employee names
        setLocations(data.data); // Update state with fetched data
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    // Set interval to fetch data every 20sec
    const intervalId = setInterval(fetchData, 20000);

    // Cleanup function to clear interval on component unmount
    return () => clearInterval(intervalId);
  }, [userProfile]);

  // Update selected employees in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem(
      "selectedEmployees",
      JSON.stringify(selectedEmployees)
    );
  }, [selectedEmployees]);

  useEffect(() => {
    if (
      mapRef.current &&
      selectedEmployees.length > 0 &&
      locations.length > 0
    ) {
      const employeeLocations = selectedEmployees
        .map((emp) => locations.filter((location) => location.empCode === emp))
        .flat();

      if (employeeLocations.length === 0) {
        if (!alertDisplayed) {
          alert("Tracking information not available.");
          setAlertDisplayed(true); // Set alertDisplayed to true to indicate that the alert has been displayed
        }
        return;
      }

      const bounds = L.latLngBounds(
        employeeLocations.map((location) => [
          parseFloat(location.latitude),
          parseFloat(location.longitude),
        ])
      );

      if (bounds.isValid()) {
        mapRef.current.fitBounds(bounds, { padding: [50, 50] });
      } else {
        alert("Bounds are not valid.");
      }
    }
  }, [selectedEmployees, locations]);

  const handleEmployeeCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const employeeTrackingAvailable = locations.some(
      (location) => location.empCode === value
    );

    if (checked) {
      // Dispatch action to add tracker with is_tracking_active set to 1
      dispatch(addTracker({ empCode: value, is_tracking_active: 1 }));
      setSelectedEmployees([...selectedEmployees, value]);
    } else {
      // Dispatch action to remove tracker with is_tracking_active set to 0
      dispatch(addTracker({ empCode: value, is_tracking_active: 0 }));
      setSelectedEmployees(selectedEmployees.filter((emp) => emp !== value));
    }
    if (checked && !employeeTrackingAvailable && !alertDisplayed) {
      alert("Tracking information not available.");
      setAlertDisplayed(true); // Set alertDisplayed to true to indicate that the alert has been displayed
      return; // Stop further execution
    }
  };

  // Function to calculate time difference in minutes
  const getTimeDifferenceInMinutes = (updatedAt) => {
    const updatedTime = new Date(updatedAt);
    const currentTime = new Date();
    const differenceInMilliseconds = currentTime - updatedTime;
    return Math.floor(differenceInMilliseconds / (1000 * 60));
  };

  // Creating Leaflet icons
  const redIcon = L.icon({
    iconUrl: redPinIcon,
    iconSize: [35, 50],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  const greenIcon = L.icon({
    iconUrl: greenPinIcon,
    iconSize: [40, 50],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });

  return (
    <>
      <Topbar />
      <div className="map-container-wrapper">
        <div className="map-container" style={{ position: "relative" }}>
          {userProfile &&
            userProfile.lock_user &&
            userProfile.lock_user.is_active === 0 && (
              <>
                <div className="blurred-overlay">
                  <div style={{ fontSize: "25px" }}>Screen Locked</div>
                  <LockIcon sx={{ fontSize: 50 }} className="lock-icon" />
                  <div>
                    <Typography variant="body1" style={{ fontSize: "15px" }}>
                      Get Premium Version To Unlock This Feature
                    </Typography>
                  </div>
                </div>

                <div className="inactive-overlay"></div>
              </>
            )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "20px",
              opacity:
                userProfile &&
                userProfile.lock_user &&
                userProfile.lock_user.is_active === 0
                  ? 0.5
                  : 1,
            }}
          >
            <div
              style={{
                flex: "0 0 25%",
                marginRight: "20px",
                height: "530px",
                overflowY: "scroll",
                background: "linear-gradient(to bottom, #4f0356, #76267d)",
                padding: "20px",
                marginTop: "10px",
                borderRadius: "10px",
              }}
            >
              <div style={{ padding: "10px" }}>
                {EmpData &&
                  EmpData.filter((employee) => employee.is_active === 1).map(
                    (employee) => (
                      <div key={employee.id}>
                        <label>
                          <Typography
                            variant="h5"
                            component="div"
                            style={{ color: "white" }}
                          >
                            <input
                              style={{ marginRight: "20px" }}
                              type="checkbox"
                              value={employee.empCode}
                              checked={selectedEmployees.includes(
                                employee.empCode
                              )}
                              onChange={handleEmployeeCheckboxChange}
                            />
                            {employee.name}
                          </Typography>
                        </label>
                      </div>
                    )
                  )}
              </div>
            </div>
            <div style={{ flex: "0 0 75%" }}>
              <MapContainer
                ref={mapRef}
                style={{
                  height: "530px",
                  width: "100%",
                  border: "2px solid #ccc",
                  marginTop: "10px",
                }}
              >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                {selectedEmployees.length > 0 &&
                  locations
                    .filter((location) =>
                      selectedEmployees.includes(location.empCode)
                    )
                    .map((location) => {
                      // Calculate time difference in minutes
                      const timeDifference = getTimeDifferenceInMinutes(
                        location.server_time
                      );

                      // Define the icon based on time difference
                      const pinIcon = timeDifference > 1 ? redIcon : greenIcon;

                      return (
                        <Marker
                          key={location.id}
                          position={[
                            parseFloat(location.latitude),
                            parseFloat(location.longitude),
                          ]}
                          icon={pinIcon}
                        >
                          <Popup>
                            <div>Emp Code: {location.empCode}</div>
                            <div>User Name: {location.user_name}</div>
                            <div>
                              Date:
                              {new Date(
                                location.server_time
                              ).toLocaleDateString()}
                            </div>
                            <div>
                              Time:
                              {new Date(
                                location.server_time
                              ).toLocaleTimeString()}
                            </div>
                          </Popup>
                        </Marker>
                      );
                    })}
              </MapContainer>
            </div>
          </div>
        </div>
      </div>
      <style>
        {`
            .blurred-overlay {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              backdrop-filter: blur(1px);
              display: flex;
              flex-direction: column; /* Ensure items are stacked vertically */
              justify-content: center;
              align-items: center;
              text-align: center; /* Center text horizontally */
              z-index: 999;
            }

          .inactive-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(255, 255, 255, 0.3); /* Adjust opacity as needed */
            z-index: 998; /* Place it below the blurred overlay */
          }
        `}
      </style>
    </>
  );
}
