import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiUrl } from "../../../apiConfig";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const fetchViewAdminPjp = createAsyncThunk(
  "ViewAdminPjp/fetchViewAdminPjp",
  async () => {
    const token = getTokenFromLocalStorage();

    const res = await fetch(`${apiUrl}/admin/pjp`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const final = await res.json();
    return final;
  }
);

const ViewAdminPjpSlice = createSlice({
  name: "ViewAdminPjp",
  initialState: {
    ViewAdminPjpData: null,
    isLoader: false,
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchViewAdminPjp.pending, (state) => {
      state.isLoader = true;
    });
    builder.addCase(fetchViewAdminPjp.fulfilled, (state, action) => {
      state.isLoader = false;
      state.ViewAdminPjpData = action.payload;
      // console.log("ViewAdminPjp data:", action.payload);
    });
    builder.addCase(fetchViewAdminPjp.rejected, (state) => {
      state.isLoader = false;
      state.isError = true;
    });
  },
});

export default ViewAdminPjpSlice.reducer;
