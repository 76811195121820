import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Select,
  MenuItem as MuiMenuItem,
} from "@mui/material";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { logoutUser } from "../../Redux/Slice/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchViewSuperAdminClients,
  fetchViewSuperAdminClientss,
} from "../../Redux/Slice/SuperAdmin/ViewSuperClientSlice";
import { fetchViewSuperAdminAllClient } from "../../Redux/Slice/SuperAdmin/ViewAllClientSuper";
import { fetchViewSuperCompany } from "../../Redux/Slice/SuperAdmin/ViewCompanySlice";
import { fetchViewAdminPjp } from "../../Redux/Slice/Admin/Pjp/ViewAdminPjpSlice";
import { fetchViewSuperAdminPjp } from "../../Redux/Slice/SuperAdmin/ViewSuperPjpSlice";
import { fetchViewAdminDealer } from "../../Redux/Slice/Admin/Dealer/ViewAdminDealerSlice";
import { fetchViewSuperAdminDealer } from "../../Redux/Slice/SuperAdmin/ViewSuperDealerSlice";
import { fetchViewSuperAdminSubdealer } from "../../Redux/Slice/SuperAdmin/ViewSuperSubdealerSlice";
import { fetchViewSuperAdminVisits } from "../../Redux/Slice/SuperAdmin/ViewSuperVisitsSlice";
import { fetchViewSuperDashboard } from "../../Redux/Slice/SuperAdmin/Dashboard/ViewSuperDashboardSlice";
import { fetchViewSuperAdminUser } from "../../Redux/Slice/SuperAdmin/ViewSuperAdminUsersSlice";

const TopbarSuperAdmin = () => {
  const toggleFullScreen = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement
    ) {
      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      } else if (docEl.mozRequestFullScreen) {
        docEl.mozRequestFullScreen();
      } else if (docEl.webkitRequestFullscreen) {
        docEl.webkitRequestFullscreen();
      }
    } else {
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      }
    }
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logoutUser());
    localStorage.removeItem("token");
    localStorage.removeItem("selectedPage");
    localStorage.removeItem("role_id");
    navigate("/login");
  };

  const [selectedCompany, setSelectedCompany] = useState(
    localStorage.getItem("selectedCompany") || ""
  );

  const CompanyData = useSelector(
    (state) => state.ViewCompany.ViewSuperCompanyData?.data || []
  );
  console.log("CompanyData", CompanyData);
  useEffect(() => {
    dispatch(fetchViewSuperCompany());
  }, [dispatch]);

  useEffect(() => {
    if (CompanyData.length > 0 && !localStorage.getItem("selectedCompany")) {
      const firstCompanyId = CompanyData[0].id;
      setSelectedCompany(firstCompanyId);
      localStorage.setItem("selectedCompany", firstCompanyId);
      dispatch(fetchViewAdminPjp(firstCompanyId));
    }
  }, [CompanyData, dispatch]);

  useEffect(() => {
    if (!localStorage.getItem("selectedPage")) {
      localStorage.setItem("selectedPage", "Dashboard");
    }
  }, []);

  const handleCompanyChange = (event) => {
    const companyId = event.target.value;
    setSelectedCompany(companyId);
    localStorage.setItem("selectedCompany", companyId);
    dispatch(fetchViewSuperAdminPjp(companyId));
    dispatch(fetchViewSuperAdminDealer(companyId));
    dispatch(fetchViewSuperAdminSubdealer(companyId));
    dispatch(fetchViewSuperAdminVisits(companyId));
    dispatch(fetchViewSuperAdminClients(companyId));
    dispatch(fetchViewSuperAdminAllClient(companyId));
    dispatch(fetchViewSuperDashboard(companyId));
    dispatch(fetchViewSuperAdminUser(companyId));
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
      sx={{
        background: "linear-gradient(to right, #4d0054, #fde1ff)",
      }}
    >
      {/* Left Side: Company Selector */}
      <Box display="flex" alignItems="center">
        <Select
          value={selectedCompany}
          onChange={handleCompanyChange}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={{
            color: "black",
            minWidth: 200,
            backgroundColor: "white",
            maxHeight: "30px",
          }}
        >
          <MuiMenuItem value="" disabled>
            Select Company
          </MuiMenuItem>
          {CompanyData.map((company) => (
            <MuiMenuItem key={company.id} value={company.id}>
              {company.name}
            </MuiMenuItem>
          ))}
        </Select>
      </Box>

      {/* Right Side: Icons */}
      <Box display="flex" alignItems="center">
        <IconButton onClick={toggleFullScreen}>
          <FullscreenIcon />
        </IconButton>
        <IconButton onClick={handleLogout}>
          <ExitToAppOutlinedIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TopbarSuperAdmin;
